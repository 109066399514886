var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "picking-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "拣货明细",
      "left-text": "返回",
      "right-text": "取消拣货",
      "left-arrow": ""
    },
    on: {
      "click-right": _vm.cancel
    }
  }), _vm.noticeText ? _c('van-sticky', {
    staticClass: "notice",
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-notice-bar', {
    attrs: {
      "left-icon": "volume-o",
      "text": _vm.noticeText
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.goodses, function (goods, index) {
    return _c('picking-goods', {
      key: index,
      attrs: {
        "goods": goods
      },
      on: {
        "pick-goods": _vm.handlePick,
        "goods-shortage": _vm.handleShortage,
        "image-preview": _vm.handleImagePreview
      }
    });
  }), 1), _vm.goodses.length > 0 ? _c('div', {
    staticClass: "fixed-bottom-btn picking-detail-container__footer"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.notPickCount > 0,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.finished
    }
  }, [_vm.notPickCount === 0 ? _c('span', [_vm._v("拣货完成")]) : _c('span', [_vm._v("还有" + _vm._s(_vm.notPickCount) + "种商品未拣货")])])], 1) : _vm._e(), _c('van-dialog', {
    attrs: {
      "title": "输入拣货数量",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1A6FF2"
    },
    on: {
      "confirm": _vm.pickGoods
    },
    model: {
      value: _vm.showInputDialog,
      callback: function callback($$v) {
        _vm.showInputDialog = $$v;
      },
      expression: "showInputDialog"
    }
  }, [_vm.inputGoods ? _c('div', {
    staticClass: "picking-detail-container__input"
  }, [_vm.inputGoods.large_unit ? _c('van-field', {
    attrs: {
      "type": "number",
      "border": false,
      "label-width": "40px",
      "label-align": "right",
      "label": _vm.inputGoods.large_unit,
      "placeholder": "请输入数量"
    },
    model: {
      value: _vm.pickLargeVolume,
      callback: function callback($$v) {
        _vm.pickLargeVolume = $$v;
      },
      expression: "pickLargeVolume"
    }
  }) : _vm._e(), _vm.inputGoods.least_unit ? _c('van-field', {
    attrs: {
      "type": "number",
      "border": "",
      "label-width": "40px",
      "label-align": "right",
      "label": _vm.inputGoods.least_unit,
      "placeholder": "请输入数量"
    },
    model: {
      value: _vm.pickLeastVolume,
      callback: function callback($$v) {
        _vm.pickLeastVolume = $$v;
      },
      expression: "pickLeastVolume"
    }
  }) : _vm._e()], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }