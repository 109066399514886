<template>
  <div class="picking-detail-container">
    <my-nav-bar
      title="拣货明细"
      left-text="返回"
      right-text="取消拣货"
      left-arrow
      @click-right="cancel"
    />
    <van-sticky v-if="noticeText" class="notice" offset-top="1.22667rem">
      <van-notice-bar left-icon="volume-o" :text="noticeText" />
    </van-sticky>
    <div class="app-main-container">
      <picking-goods
        v-for="(goods, index) in goodses"
        :key="index"
        :goods="goods"
        @pick-goods="handlePick"
        @goods-shortage="handleShortage"
        @image-preview="handleImagePreview"
      />
    </div>
    <div v-if="goodses.length > 0" class="fixed-bottom-btn picking-detail-container__footer">
      <van-button :disabled="notPickCount > 0" square block @click="finished">
        <span v-if="notPickCount === 0">拣货完成</span>
        <span v-else>还有{{ notPickCount }}种商品未拣货</span>
      </van-button>
    </div>
    <van-dialog
      v-model="showInputDialog"
      title="输入拣货数量"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1A6FF2"
      @confirm="pickGoods"
    >
      <div v-if="inputGoods" class="picking-detail-container__input">
        <van-field
          v-if="inputGoods.large_unit"
          v-model="pickLargeVolume"
          type="number"
          :border="false"
          label-width="40px"
          label-align="right"
          :label="inputGoods.large_unit"
          placeholder="请输入数量"
        />
        <van-field
          v-if="inputGoods.least_unit"
          v-model="pickLeastVolume"
          type="number"
          border
          label-width="40px"
          label-align="right"
          :label="inputGoods.least_unit"
          placeholder="请输入数量"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import PickingGoods from './components/goods'
import pickingApi, { getNotice } from '@/api/picking'
import { now, sortByKey } from '@/utils'
import MyNavBar from '@/components/my-nav-bar'
import { ImagePreview } from 'vant'
export default {
  name: 'PickingDetail',
  components: { PickingGoods, MyNavBar },
  data() {
    return {
      goodses: [],
      orderId: this.$route.query.order_id,
      showInputDialog: false,
      inputGoods: null,
      pickLargeVolume: '',
      pickLeastVolume: '',
      noticeText: '',
      timer: null
    }
  },
  computed: {
    notPickCount() {
      let notPickCount = this.goodses.length
      this.goodses.map(goods => {
        if (goods.pick_finished_datetime) { notPickCount-- }
      })
      return notPickCount
    }
  },
  created() {
    this.getDetail()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getDetail() {
      const params = {
        mode: this.$route.query.mode,
        warehouse_id: this.$route.query.warehouse_id,
        order_id: this.orderId,
        pick_number: this.$store.state.pickNumber
      }
      this.beginLoad()
      pickingApi.detail(params).then(res => {
        this.goodses = res.data
        this.endLoad()

        if (Number(this.$route.query.mode) !== 1) {
          this.handleGetNotice()
          this.timer = setInterval(() => {
            this.handleGetNotice()
          }, 10000)
        }
      })
    },
    handleGetNotice() {
      const params = {
        warehouse_id: this.$route.query.warehouse_id
      }
      getNotice(params).then(res => {
        this.noticeText = res.data
      })
    },
    cancel() {
      this.confirm('确定取消拣货吗？').then(() => {
        const data = {
          mode: this.$route.query.mode,
          warehouse_id: this.$route.query.warehouse_id,
          order_id: this.$route.query.order_id
        }
        pickingApi.cancel(data).then(() => {
          this.$router.go(-1)
        })
      })
    },
    handleShortage(goods) {
      this.inputGoods = goods
      this.pickLargeVolume = goods.large_volume
      this.pickLeastVolume = goods.least_volume
      this.showInputDialog = true
    },
    handlePick(goods) {
      this.inputGoods = goods
      this.pickLargeVolume = goods.large_volume
      this.pickLeastVolume = goods.least_volume
      this.pickGoods()
    },
    pickGoods() {
      const data = {
        mode: this.$route.query.mode,
        warehouse_id: this.$route.query.warehouse_id,
        goods_id: this.inputGoods.goods_id,
        order_id: this.$route.query.order_id,
        pick_large_volume: this.pickLargeVolume ? this.pickLargeVolume : 0,
        pick_least_volume: this.pickLeastVolume ? this.pickLeastVolume : 0
      }
      this.beginLoad()
      pickingApi.goods(data).then(res => {
        this.success(res.msg)
        this.inputGoods.pick_large_volume = this.pickLargeVolume
        this.inputGoods.pick_least_volume = this.pickLeastVolume
        const needSort = !this.inputGoods.pick_finished_datetime
        this.inputGoods.pick_finished_datetime = now()
        if (needSort) {
          // 已拣货的移到最后面
          this.goodses.sort(sortByKey('pick_finished_datetime'))
        }
      })
    },
    finished() {
      this.beginLoad()
      const data = {
        mode: this.$route.query.mode,
        warehouse_id: this.$route.query.warehouse_id,
        order_id: this.$route.query.order_id
      }
      pickingApi.finish(data).then(res => {
        if (res.code === 10004) {
          setTimeout(() => {
            this.getDetail()
          }, 1500)
        } else {
          this.success(res.msg)
          this.$router.go(-1)
        }
      })
    },
    handleImagePreview(image) {
      ImagePreview([image])
    }
  }
}
</script>

<style lang="scss" scoped>
 .picking-detail-container {
   padding: 15px 15px 55px 15px;
   &__footer {
     background-color: #fff;
     z-index: 99;
     .van-button {
       height: 50px;
     }
   }
   &__input {
     padding: 10px 15px;
     /*.van-field__value {*/
       /*border: 1px #f2f2f2 solid;*/
       /*padding: 0 10px;*/
     /*}*/
     /*.van-field__label--right {*/
       /*padding-right: unset;*/
     /*}*/
   }
 }
 .notice {
   height: 40px;
 }
</style>
